import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@/assets/main.scss";
import 'vuetify/styles';
import {createVuetify} from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import * as labsComponents from 'vuetify/labs/components';

import {createPinia} from 'pinia';

const pinia = createPinia();

const vuetify = createVuetify({
    components: {
        ...components,
        ...labsComponents,
    },
    directives,
    icons: {
        defaultSet: 'mdi',
    },
})

const toastOptions = {
    position: 'bottom-right',
    duration: 3000,
}

createApp(App)
    .use(pinia)
    .use(router)
    .use(vuetify)
    .use(Toast, toastOptions)
    .mount('#app')
